import React from 'react';
import { HiUserGroup, HiOutlineCar } from 'react-icons/hi';
import { Link } from 'react-router-dom';

const Home = ({ChooseUs,CarNumbers}) => {
 

  return (
    <div className="flex flex-col lg:flex-row">
     <section className="flex-1 bg-slate-950 py-12">
  <div className="container mx-auto">
    <h2 className="text-4xl text-white mb-6 lg:ml-6 text-center lg:text-left font-extrabold">Our Fleet</h2>
    <div className='flex flex-col lg:flex-row px-4'>
    <div className="bg-white  lg:basis-1/3  p-6 rounded-lg shadow-lg flex flex-col items-center lg:max-w-xs">
              <i class="fas fa-car text-3xl text-[#004e7e] mb-4"></i>
              <h3 className="text-2xl font-semibold text-gray-800 mb-2">Car Number</h3>
              <p className="text-4xl font-bold text-[#004e7e]">{CarNumbers}</p>
            </div>
    <p className="text-xl p-4 lg:basis-2/3  text-gray-300 text-center lg:text-left  mx-auto">
    We offer Professional van rental services throughout Michigan, Unlike other van rentals companies, the vehicle you choose is guaranteed by your deposit.
    In addition, we believe in a stress free reservation system, so reserve your van today.

    </p>
    </div>
  
  </div>
</section>


      <section className="flex-1 bg-gray-100 py-12">
        <div className="container mx-auto px-5 lg:px-20">
          <h2 className="text-4xl text-gray-800 font-extrabold text-left mb-8">Why Choose Us</h2>
          <ul className="text-lg text-gray-700 text-left">
            {ChooseUs.map((reason) => (
              <li key={reason.id} className="mb-2">
                <i className="fas fa-check text-[#004e7e] mr-2"></i>
                {reason.Text}
              </li>
            ))}
          </ul>
          <Link to="/About" className="bg-[#004e7e]  mt-10 inline-block text-white hover:bg-white hover:text-[#004e7e] py-2 px-4 rounded-full text-lg font-semibold transition duration-300"> Learn More About Us</Link>

        </div>
      </section>
    </div>
  );
};

export default Home;
