import axios from "axios"

const API_URL = 'https://api.mivanrental.com'
const fetchVehicles = async() => {
    const res = await axios.post(API_URL,{
        query:`query{
            GetVehicles{
                id
                MainInfo{
                    Title
                    Slug
                    Description
                    Brand{
                        id
                        Name
                        Logo
                    }
                    Model
                }
                YoutubeLink
                MainImage{
                    FilePath
                }
                Galleries
                Features{
                    Transmission
                    Fuel
                    BodyType
                    Passengers
                    AirConditioning
                    Stereo
                    Luggage
                    PowerSteering
                    Doors
                    InteriorMaterial
                    HDTV
                    PowerWindows
                    RemoteKeyLessEntry
                    BackUpCam
                    DVD
                }
                isAvailable
            }
        }`
    })
    return res.data.data.GetVehicles
}



const fetchFaqs = async() => {
    const res = await axios.post(API_URL,{
        query:`query{
            GetFaqs{
                id
                Question
                Answer
            }
        }`
    })
    return res.data.data.GetFaqs
}

const fetchChooses = async() => {
    const res = await axios.post(API_URL,{
        query:`query{
            GetChooseUs{
                id
                Text
            }
        }`
    })
    return res.data.data.GetChooseUs
}

const fetchCarousel = async() => { 
    const res = await axios.post(API_URL,{
        query:`query{
            GetCarousels{
                id
                FilePath
                Alt
            }
        }`
    })
    return res.data.data.GetCarousels
}


const fetchVehiclesCount = async () =>{
    const res = await axios.post(API_URL,{
        query:`query{
            GetVehicleCount{
                Key
                Value
            }
        }`
    })
    return res.data.data.GetVehicleCount.Value

}


const fetchAll = async() =>{
    return {
        vehicles: await fetchVehicles(),
        faqs:await fetchFaqs(),
        chooses:await fetchChooses(),
        carousel:await fetchCarousel(),
        vehiclesCount:await fetchVehiclesCount()
    }
}

export default fetchAll