import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import './Vehicles.css'
const FilterSystem = ({uniqueBodies,uniqueTransmission,uniqueFuel,uniquePassenger,setFilteredBodies,setFilteredTransmission,setFilteredFuel,setFilteredPassenger,filteredPassenger}) =>{
    return(
        <>
            <div className="bg-gray-800 rounded-lg shadow-lg text-white sticky top-5 w-full p-4">
                <h3 className="font-bold text-xl mb-2">Filter Vehicles</h3>
                <hr />
                <div className="mt-3">
                    <div className="mb-4">
                        <label htmlFor="fullname" className="block text-gray-300 text-sm font-semibold mb-2">
                        Body Type
                        </label>
                        <select onChange={(e)=>setFilteredBodies(e.target.value)} className=" bg-gray-100 text-black w-full border border-[#004e7e]  py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-bg-gray-50 focus:border-[#004e7e]">
                            <option value="all">All</option>
                            {
                                uniqueBodies.map((bd,index)=>(
                                    <option value={bd} key={bd+index}>{bd}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="fullname" className="block text-gray-300 text-sm font-semibold mb-2">
                        Transmission
                        </label>
                        <select onChange={(e)=>setFilteredTransmission(e.target.value)} className=" bg-gray-100 text-black w-full border border-[#004e7e]  py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-bg-gray-50 focus:border-[#004e7e]">
                            <option value="all">All</option>
                            {
                                uniqueTransmission.map((bd,index)=>(
                                    <option value={bd} key={bd+index}>{bd}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="fullname" className="block text-gray-300 text-sm font-semibold mb-2">
                        Fuel
                        </label>
                        <select onChange={(e)=>setFilteredFuel(e.target.value)} className=" bg-gray-100 text-black w-full border border-[#004e7e] py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-bg-gray-50 focus:border-[#004e7e]">
                            <option value="all">All</option>
                            {
                                uniqueFuel.map((bd,index)=>(
                                    <option value={bd} key={bd+index}>{bd}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="fullname" className="block text-gray-300 text-sm font-semibold mb-2">
                        Passengers
                        </label>
                        <select value={filteredPassenger} onChange={(e)=>setFilteredPassenger(e.target.value)} className=" bg-gray-100 text-black w-full border border-[#004e7e]  py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-bg-gray-50 focus:border-[#004e7e]">
                            <option value="all">All</option>
                            {
                                uniquePassenger.map((bd,index)=>(
                                    <option value={bd} key={bd+index}>{bd}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>
        </>
    )
}
const Vehicles = ({vehicles}) =>{
    const [uniqueBodies,setUniqueBodies] = useState([])
    const [uniqueTransmission,setUniqueTransmission] = useState([])
    const [uniqueFuel,setUniqueFuel] = useState([])
    const [uniquePassenger,setUniquePassenger] = useState([])


    const [filteredBodies,setFilteredBodies] = useState('all')
    const [filteredTransmission,setFilteredTransmission] = useState('all')
    const [filteredFuel,setFilteredFuel] = useState('all')
    const [filteredPassenger,setFilteredPassenger] = useState('all')

    const {passengers} = useParams()


    useEffect(()=>{
        window.scrollTo(0, 0)
        document.title = 'Michigan Car & Van Rental | Our Vehicles'
        setUniqueBodies([...new Set(vehicles.map((item) => item.Features.BodyType))])
        setUniqueTransmission([...new Set(vehicles.map((item)=> item.Features.Transmission))])
        setUniqueFuel([...new Set(vehicles.map((item)=> item.Features.Fuel))])
        setUniquePassenger([...new Set(vehicles.map((item)=> item.Features.Passengers))])

    },[vehicles])

    useEffect(()=>{
        passengers !== undefined ? setFilteredPassenger(passengers) : setFilteredPassenger('all')
    },[passengers])


    const filteredVehicles = vehicles.filter((vehicle) => {
        const bodyTypeMatch = filteredBodies === 'all' || vehicle.Features.BodyType === filteredBodies;
        const transmissionMatch = filteredTransmission === 'all' || vehicle.Features.Transmission === filteredTransmission;
        const fuelMatch = filteredFuel === 'all' || vehicle.Features.Fuel === filteredFuel;
        const passengerMatch = filteredPassenger === 'all' || vehicle.Features.Passengers ===  parseInt(filteredPassenger);
    
        return bodyTypeMatch && transmissionMatch && fuelMatch && passengerMatch;
      })
    return(
        <>
            <div className="container px-5 lg:px-0 mx-auto w-full h-full">
                <div className="space-y-5 lg:grid lg:grid-cols-3 my-10 gap-6">
                    <div>
                        <FilterSystem 
                        filteredPassenger={filteredPassenger}
                        setFilteredBodies={setFilteredBodies} setFilteredTransmission={setFilteredTransmission} setFilteredFuel={setFilteredFuel} setFilteredPassenger={setFilteredPassenger}
                        uniqueBodies={uniqueBodies} uniqueTransmission={uniqueTransmission} uniqueFuel={uniqueFuel} uniquePassenger={uniquePassenger}/>
                    </div>
                    <div className={`col-span-2 ${filteredPassenger !== 'all' ? '' : ''}`}>
                        <div className="grid w-full grid-cols-1 lg:grid-cols-2 gap-4">
                        {filteredVehicles?.map((car) => (
                            <div key={car.id} className="relative rounded-lg vehicle-item overflow-hidden shadow-lg">
                                <Link to={"/Vehicle/"+car.MainInfo.Slug}>
                                    <img src={`https://cloud.mivanrental.com/`+car.MainImage.FilePath} alt={`${car.MainInfo.Brand.Name} ${car.MainInfo.Model}`} className="w-full h-60 object-cover" />
                                </Link>
                                <div className="absolute top-0 left-0 p-2 bg-[#004e7e] text-white text-xs font-semibold rounded-tl rounded-br">
                                {car.MainInfo.Brand.Name}
                                </div>
                                <div className="p-4">
                                <Link to={"/Vehicle/"+car.MainInfo.Slug} >
                                    <h3 className="text-2xl font-semibold mb-2">{car.MainInfo.Title}</h3>
\                               </Link>
                                <div className="grid grid-cols-2 gap-2">
                                    <div>
                                    <p className="text-gray-600 mb-2"><strong>Transmission:</strong> {car.Features.Transmission}</p>
                                    <p className="text-gray-600 mb-2"><strong>Body Type:</strong> {car.Features.BodyType}</p>
                                    <p className="text-gray-600 mb-2"><strong>Fuel:</strong> {car.Features.Fuel}</p>
                                    </div>
                                    <div>
                                    <p className="text-gray-600 mb-2"><strong>Passenger Number:</strong> {car.Features.Passengers}</p>
                                    <p className="text-gray-600 mb-2"><strong>Air Condition:</strong> {car.Features.AirConditioning ? <i class="fa-solid fa-check text-green-500"></i> : <i class="fa-solid fa-xmark text-red-500"></i>}</p>
                                    </div>
                                </div>
                                </div>
                                <div className="absolute bottom-2 right-2">
                                <Link to={"/Vehicle/"+car.MainInfo.Slug} className="bg-gray-800 w-8 h-8 flex justify-center align-center text-white p-2 rounded-full hover:bg-gray-600 focus:outline-none">
                                <i class="fa-solid fa-arrow-trend-up"></i>
                                </Link>
                                </div>
                            </div>
                            ))}
                        </div>
                        
                    </div>
               </div>
            </div>
        </>
    )
}

export default Vehicles