import React, { useState } from 'react';

const FaqItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-4">
      <button
        className="w-full flex items-center justify-between py-2 px-4 text-left bg-gray-100 text-gray-800 hover:bg-gray-200 transition duration-300 focus:outline-none"
        onClick={toggleAccordion}
      >
        <span className="text-lg font-semibold">{question}</span>
        <span className="ml-4">
          {isOpen ? (
            <i className="fas fa-chevron-up"></i>
          ) : (
            <i className="fas fa-chevron-down"></i>
          )}
        </span>
      </button>
      {isOpen && (
        <div className="bg-white text-gray-700 p-4 border-t border-gray-200">
          <p>{answer}</p>
        </div>
      )}
    </div>
  );
};

const FAQSection = ({faqItems}) => {
   
      

  return (
    <section className="container mx-auto px-5 lg:px-0 py-8">
      <h2 className="text-3xl lg:text-4xl font-extrabold text-center mb-8">Frequently Asked Questions</h2>
      {faqItems.map((item) => (
        <FaqItem key={item.id} question={item.Question} answer={item.Answer} />
      ))}
    </section>
  );
};

export default FAQSection;
