import axios from "axios"
import { useState } from "react"

const AirportPickUp = () =>{
    const [fullname,setFullName] = useState('')
    const [email,setEmail] = useState('')
    const [phone,setPhone] = useState('')
    const [msg,setMessage] = useState('')
    const [res,setRes] = useState('')

    const handleClick = async() =>{
        if(fullname !== '' && email !== '' &&  msg !== ''){
            const res = await axios.post(`https://api.mivanrental.com`,{
            query:`mutation{
                AddNewAirPortPickUp(fullname:"${fullname}",email:"${email}",phonenumber:"${phone}",message:"${msg}"){
                    id
                }
            }`
        })
        if(res.data.data.AddNewAirPortPickUp.id){
            setRes('SENT')
        }
        else{
            setRes('ERR')
        }
        }
        else{
            setRes('MISS')
        }
        
    }
    return(
        <>
        <div className="md:col-span-8 p-10">
          <h2 className="mb-5 font-bold text-center text-xl">Airport Pickup</h2>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">Full name</label>
              <input value={fullname} onChange={(e)=>setFullName(e.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-email" type="email" placeholder="John Doe ..." />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">Email</label>
              <input value={email} onChange={(e)=>setEmail(e.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="email" placeholder="********@*****.**" />
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">Phone Number</label>
              <input value={phone} onChange={(e)=>setPhone(e.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="tel" placeholder="00 123 456 789" />
            </div>
          </div>
         
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">Your Message</label>
              <textarea value={msg} onChange={(e)=>setMessage(e.target.value)} rows="5" className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"></textarea>
            </div>
            <div className="flex justify-end w-full px-3">
             
              <button onClick={handleClick} className="shadow bg-[#004e7e] hover:bg-[#004e7edd] focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded" type="submit">Send</button>
            </div>
            {
               res !== '' && (res === 'SENT' ? <p className='text-green-700 w-full mt-3 block text-center'>Message Has Sent Successfully</p> : res === "ERR" ? <p className='text-red-700 w-full mt-3 block text-center'>Try Again Later </p>: <p className='text-red-700 w-full mt-3 block text-center'>Make sure all required fields filled</p> )
            }
           
          </div>
        </div>
        </>
    )
}

export default AirportPickUp