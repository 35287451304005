import React from 'react';
import { FaFacebook, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-900 px-5 lg:px-0 text-gray-300 py-10">
      <div className="container mx-auto flex flex-wrap justify-between">
        <div className="w-full md:w-1/4 mb-6 md:mb-0">
          <h2 className="text-3xl font-extrabold text-[#004e7e]">Michigan Car & Van Rental</h2>
          <p className="mt-4 text-sm text-muted">
            Providing top-quality car rental services to meet your travel needs.
          </p>
          <div className='flex flex-col mt-4 space-y-2'>
            <span>Monday – Friday 8:30 am – 5:00 pm</span>
            <span>Saturday 9:00 am – 12:00 pm</span>
            <span>Closed Sunday and Holidays</span>
          </div>
          <div className='mt-3 flex flex-row'>
            <a target='_blank' className='mr-3' href="http://www.facebook.com/michigancarandvanrental"><FaFacebook className='text-xl'/></a>
            <a target='_blank' href="http://www.youtube.com/@michigancarandvanrental"><FaYoutube  className='text-xl'/></a>
          </div>
        </div>
        <div className="w-full md:w-1/4 mb-6 md:mb-0">
          <h3 className="text-xl font-semibold">Contact Us</h3>
          <ul className="mt-4 space-y-2 text-sm">
            <li><strong>Email:</strong> support@mivanrenta.com</li>
            <li><strong>Phone:</strong> (586) 755-9290</li>
            <li><strong>Address:</strong> 22768 Hoover Rd, Warren MI 48089</li>
          </ul>
        </div>
       
        <div className="w-full md:w-1/4 mb-6 md:mb-0">
          <h3 className="text-xl font-semibold">Links</h3>
          <ul className="mt-4 space-y-2 text-sm">
            <li>
              <Link to="/" className="text-gray-300 hover:text-[#004e7e] transition duration-300">
                Home
              </Link>
            </li>
            <li>
              <Link to="/Vehicles" className="text-gray-300 hover:text-[#004e7e] transition duration-300">
                Vehicles
              </Link>
            </li>
           
            <li>
              <Link to="/Contact" className="text-gray-300 hover:text-[#004e7e] transition duration-300">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
        
      </div>
      <div className="mt-8 text-sm text-center">
        &copy; {new Date().getFullYear()} Mivanrental. All rights reserved.
      </div>
      <div className='text-xs text-center w-full text-gray-500'>Powerd by UnlimitedWorld | Developed by ULCode</div>
    </footer>
  );
};

export default Footer;
