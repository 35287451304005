import React from 'react';
import { FaEnvelope, FaMapMarker, FaPhone } from 'react-icons/fa';

const SmallNavbar = () => {
  return (
    <div className=" bg-slate-950 text-white p-2 ">
        <div className='container mx-auto flex flex-col lg:flex-row lg:items-center justify-between'>
        <div className="flex items-center space-x-2">
        <FaMapMarker className='text-[#004e7e]' />
        <a href='https://maps.app.goo.gl/eShcueRZYREJrRWW9' target='_blank'>22768 Hoover Rd, Warren MI 48089</a>
      </div>

      <div className='flex flex-col lg:flex-row lg:items-center justify-between lg:space-x-8'>
      <div className=" flex items-center space-x-2">
        <FaEnvelope className='text-[#004e7e]' />
        <a href='mailto:support@mivanrenta.com'>support@mivanrenta.com</a>
      </div>
     
      <div className="flex items-center space-x-2">
        <FaPhone className='text-[#004e7e]' />
        <a href='tel:(586) 755-9290'>(586) 755-9290</a>
      </div>
      </div>
       

        </div>
      
    </div>
  );
};

export default SmallNavbar;
