import Navbar from "./Components/Navbar";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./Pages/Home";
import About from "./Pages/About";
import Product from "./Pages/Product";
import Footer from "./Components/Footer";
import { useEffect, useState } from "react";
import fetchAll from "./Controller/Functions";
import Loader from "./Components/Loader";
import Vehicle from "./Pages/Vehicle";
import Vehicles from "./Pages/Vehicles";
import Contact from "./Pages/Contact";
import CustomModal from "./Components/Modal";
import  './App.css'
import ShuttleBus from "./Components/ShuttleBus";
import AirportPickUp from "./Components/PickUp";

export default function App() {
  const [carousel,setCarousel] = useState([])
  const [faqs,setFaqs] = useState([])
  const [chooses,setChooses] = useState([])
  const [vehicles,setVehicles] = useState([])
  const [vehiclesCount,setVehiclesCount] = useState('0')


  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modType,setModType] = useState('')



  const closeModal = () => {
    setModalIsOpen(false);
  }

  const [loader,setLoader] = useState(true)

  const LoadWeb = async() =>{
    await fetchAll().then((fetched_data)=>{
      setCarousel(fetched_data.carousel)
      setFaqs(fetched_data.faqs)
      setChooses(fetched_data.chooses)
      setVehicles(fetched_data.vehicles)
      setVehiclesCount(fetched_data.vehiclesCount)
    }).then(()=>{
        setLoader(false)
    })
  }
  useEffect(()=>{
    LoadWeb()

  },[])

  return (
   <>

   {
    loader ? <>
    <Loader />
    </> : (

      <>
    
         <Navbar />
     
         <CustomModal isOpen={modalIsOpen} closeModal={closeModal}>
              <button onClick={closeModal} className="exit_modal"><i class="fa-solid fa-xmark"></i></button>
              {modType === 'A' ? <ShuttleBus /> : <AirportPickUp />}
         </CustomModal>
      <Routes>
          <Route path="/" element={<Home state={setModType} show={setModalIsOpen} vehiclesCount={vehiclesCount} Carousel={carousel} ChooseUs={chooses} Vehicles={vehicles}  Faqs={faqs} />} />
          <Route path="/About" element={<About /> } />
          <Route path="/Vehicles/:passengers?" element={<Vehicles vehicles={vehicles} /> } />
          <Route path="/Vehicle/:vehicleSlug" element={<Vehicle vehicles={vehicles} /> } />
          <Route path="/Contact" element={<Contact /> } />
       </Routes>

       <Footer />
      </>
    )
   }


   </>  
  )
}