import React from 'react';
import { FaCar, FaRegPaperPlane, FaPhone } from 'react-icons/fa';

const HowItWorks = () => {
  return (
    <section className=" py-12">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl text-center lg:text-center font-bold mb-8">How It Works</h2>
        <div className="px-4 lg:px-0 grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="p-6 border rounded-lg shadow-md transform hover:scale-105 transition-transform">
            <div className="bg-[#004e7e] text-white rounded-full h-16 w-16 flex items-center justify-center mx-auto mb-4">
              <FaCar className="h-8 w-8" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Choose Your Car</h3>
            <p className="text-gray-600">
              Browse our selection of high-quality cars and choose the one that suits your needs.
            </p>
          </div>
          <div className="p-6 border rounded-lg shadow-md transform hover:scale-105 transition-transform">
            <div className="bg-[#004e7e] text-white rounded-full h-16 w-16 flex items-center justify-center mx-auto mb-4">
              <FaRegPaperPlane className="h-8 w-8" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Send a Request</h3>
            <p className="text-gray-600">
              Fill out the request form with your preferences and requirements.
            </p>
          </div>
          <div className="p-6 border rounded-lg shadow-md transform hover:scale-105 transition-transform">
            <div className="bg-[#004e7e] text-white rounded-full h-16 w-16 flex items-center justify-center mx-auto mb-4">
              <FaPhone className="h-8 w-8" />
            </div>
            <h3 className="text-xl font-semibold mb-2">We Will Contact You</h3>
            <p className="text-gray-600">
              Our team will contact you with all the details and options available for your request.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
