import React from 'react';
import { Link } from 'react-router-dom';



const PopularCarSection = ({Vehicles}) => {
  return (
    <section className="bg-gray-100 px-4 lg:px-0 py-12">
    <div className="container mx-auto">
      <h2 className="text-4xl font-extrabold text-[#004e7e] mb-2 text-center">Find the best Van to rent</h2>
      <p className='text-center text-gray-500'>Here at Michigan Van Rental, we offer corporate discounts. If you are looking for a great travel van rental for 'concerts, casinos, events, golf trips,sports teams, youth soccer tournaments', we will have them ready for you.</p>
      <div className="grid grid-cols-1 md:grid-cols-2 mt-8 lg:grid-cols-3 gap-6">
        {Vehicles.slice(0, 6).map((car) => (
          <div key={car.id} className="relative rounded-lg overflow-hidden shadow-lg">
            <Link to={"/Vehicle/"+car.MainInfo.Slug}>
              <img src={`https://cloud.mivanrental.com/`+car.MainImage.FilePath} alt={`${car.MainInfo.Brand.Name} ${car.MainInfo.Model}`} className="w-full h-60 object-cover" />
            </Link>
            <div className="absolute top-0 left-0 p-2 bg-[#004e7e] text-white text-xs font-semibold rounded-tl rounded-br">
              {car.MainInfo.Brand.Name}
            </div>
            <div className="p-4">
              <Link to={"/Vehicle/"+car.MainInfo.Slug}>
                  <h3 className="text-2xl font-semibold mb-2">{car.MainInfo.Title}</h3>
              </Link>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <p className="text-gray-600 mb-2"><strong>Transmission:</strong> {car.Features.Transmission}</p>
                  <p className="text-gray-600 mb-2"><strong>Body Type:</strong> {car.Features.BodyType}</p>
                  <p className="text-gray-600 mb-2"><strong>Fuel:</strong> {car.Features.Fuel}</p>
                </div>
                <div>
                  <p className="text-gray-600 mb-2"><strong>Passenger Number:</strong> {car.Features.Passengers}</p>
                  <p className="text-gray-600 mb-2"><strong>Air Condition:</strong> {car.Features.AirConditioning ? <i class="fa-solid fa-check text-green-500"></i> : <i class="fa-solid fa-xmark text-red-500"></i>}</p>
                </div>
              </div>
            </div>
            <div className="absolute bottom-2 right-2">
              <Link to={"/Vehicle/"+car.MainInfo.Slug} className="bg-gray-800 w-8 h-8 flex justify-center align-center text-white p-2 rounded-full hover:bg-gray-600 focus:outline-none">
              <i class="fa-solid fa-arrow-trend-up"></i>
              </Link>
            </div>
          </div>
        ))}
      </div>
      <div className="text-center mt-6">
        <Link to='/Vehicles' className="bg-[#004e7e] text-white px-6 py-3 rounded-full">
          Show More
        </Link>
      </div>
    </div>
  </section>
  );
};

export default PopularCarSection;
