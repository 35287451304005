import React, { useState } from 'react';
import logo from './../Images/logo.png'
import SmallNavbar from './SmallNav';
import { Link } from 'react-router-dom';
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
    <SmallNavbar />

    <nav className="bg-slate-900 bg-opacity-75 p-6 shadow-md">
      <div className="max-w-6xl mx-auto flex items-center justify-between">
        <Link to="/" className="flex items-center">
          <img src={logo} alt="Logo" className="h-12 mr-2" />
        </Link>

        <div className="hidden md:flex space-x-4">
          <Link to="/About" className="text-white font-semibold hover:text-gray-300">
            About Us
          </Link>
          <Link to="/Vehicles" className="text-white font-semibold hover:text-gray-300">
            Vehicles
          </Link>
          <Link to="/Contact" className="text-white font-semibold hover:text-gray-300">
            Contact Us
          </Link>
        </div>

        <div className="md:hidden flex items-center">
          <button onClick={toggleMenu} className="text-white">
            <svg
              className="w-6 h-6"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isOpen ? (
                <path d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden">
          <a href="/about" className="block p-4 text-white hover:text-orange-300">
            About Us
          </a>
          <a href="/vehicles" className="block p-4 text-white hover:text-orange-300">
            Vehicles
          </a>
          <a href="/contact" className="block p-4 text-white hover:text-orange-300">
            Contact Us
          </a>
        </div>
      )}
    </nav>
    </>
    
  );
};

export default Navbar;
