import { useEffect, useState } from "react"
import CarouselHeader from "../Components/Home/Carousel"
import Facts from "../Components/Home/Facts"
import FilterBox from "../Components/Home/FilterBox"
import Header from "../Components/Home/Header"
import HowItWorks from "../Components/Home/HowItWorks"
import PopularCarSection from "../Components/Home/PopularCar"
import FAQSection from "../Components/Home/Questions"

const Home = ({ Carousel, ChooseUs,vehiclesCount, Vehicles, Faqs ,state,show}) =>{
    const [headerCarousel,setHeaderCarousel] = useState([])
    const [uniquePassenger,setUniquePassenger] = useState([])

    useEffect(()=>{
        setHeaderCarousel(Carousel)
    },[Carousel])

    useEffect(()=>{
        document.title = 'Michigan Car & Van Rental | HomePage';

        setUniquePassenger([...new Set(Vehicles.map((item)=> item.Features.Passengers))])

    },[Vehicles])
    return(
        <>
            <CarouselHeader Slides={headerCarousel} />
            <section>
                <FilterBox setModType={state} setModalIsOpen={show} filter={uniquePassenger} />
                <br />
            </section>

            <PopularCarSection Vehicles={Vehicles} />
            <HowItWorks />

            <Facts ChooseUs={ChooseUs} CarNumbers={vehiclesCount} />

            <FAQSection faqItems={Faqs} />
        </>
    )
}

export default Home