import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CarouselHeader.css'; 

const CarouselHeader = ({Slides}) => {

  const [slides,setSildes] = useState([])

  useEffect(()=>{
    setSildes(Slides)
    console.log(Slides)
  },[Slides])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="carousel-header hidden md:block">
      <Slider {...settings}>
      {
  slides.map((car) => (
    <div key={car.id}> 
      <img src={"https://cloud.mivanrental.com/" + car.FilePath} alt={car.Alt} />
    </div>
  ))
}

        
       
      </Slider>
    </div>
  );
};

export default CarouselHeader;
