import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const FilterBox = ({filter,setModType,setModalIsOpen}) => {
  const [filteredPassenger,setFilteredPassenger] = useState('all')
  const navigate = useNavigate()

  const handleClick = () => {
    filteredPassenger === 'all' ? navigate('/Vehicles') : navigate('/Vehicles/'+filteredPassenger)
    
  };

  const shuttle = () =>{
    setModType("A")
    setModalIsOpen(true)
  }
  const pick = () =>{
    setModType("B")
    setModalIsOpen(true)
  }

  return (

    <div className='flex flex-col lg:flex-row container py-20 mx-auto'>
      <div className='px-5 lg:px-0 lg:w-2/3'>
      <div className="md:w-2/3 text-center  space-y-4 lg:space-y-0 md:text-left md:pr-8">
          <span className="text-lg italic mb-6 bg-[#004e7e] py-2 px-3 mb-2 inline-block text-white  rounded-full"> <i class="fa-solid fa-thumbs-up"></i> 100% Trusted Van rental platform in Michigan</span>
          <h1 className="text-4xl lg:text-6xl font-bold mb-2">Michigan Van Rental</h1>
          <p className="text-lg mb-8 inline-block">MICHIGAN CAR AND VAN RENTAL Inc. has been family owned and operated since 1992. What sets us apart from all the other van rental companies is our personalized customer service. We go out of our way for our customers in many ways. We provide you with the specific service that you need.</p>
          <button onClick={shuttle} className="bg-[#004e7e] text-white hover:bg-[#004e7e] hover:text-white py-3 px-5 rounded-full text-xl font-semibold transition duration-300">Shuttle Bus</button>
          <button onClick={pick}  className="bg-[#004e7e] mx-3 text-white hover:bg-[#004e7e] hover:text-white py-3 px-5 rounded-full text-xl font-semibold transition duration-300">Airport Pickup</button>
        </div>
      </div>
      <div className=" lg:w-1/3 container   bg-slate-900 py-14 px-6 shadow-lg mt-6 lg:rounded-xl space-x-4">
      <div className="flex flex-col justify-center items-center space-x-4">
        <div className='w-full'>
          <h3 className='text-white text-2xl font-bold mb-5'>Search Vehicles</h3>
          <label htmlFor="condition" className="block text-white font-medium">Passengers:</label>
          <select onChange={(e)=>setFilteredPassenger(e.target.value)} id="condition" className="block w-full p-2 border border-gray-300 rounded">
            <option value="all">All</option>
            {filter.map((ps) => (
              <option key={ps} value={ps}>{ps}</option>
            ))}
          </select>
        </div>
        <div className="mt-3 w-full px-5 flex justify-end align-end">
        <button onClick={handleClick} className="bg-white text-[#004e7e] mt-2 px-4 py-2 rounded hover:text-white hover:bg-[#004e7e]">Search</button>
      </div>
      </div>
    </div>
    </div>
  );
};

export default FilterBox;
