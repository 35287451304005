import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import axios from "axios";

const Gallery = ({ images }) => {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
  
    const openLightbox = (index) => {
      setPhotoIndex(index);
      setIsOpen(true);
    };
  
    const closeLightbox = () => {
      setPhotoIndex(0);
      setIsOpen(false);
    };
  
    return (
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        
        {images?.map((image, index) => (
          <div key={index} onClick={() => openLightbox(index)} className="cursor-pointer ">
            <img  src={`https://cloud.mivanrental.com/`+image} alt={`Image ${index}`} className="w-full h-auto rounded shadow-lg" />
          </div>
        ))}
        {isOpen && (
          <Lightbox
            mainSrc={`https://cloud.mivanrental.com/`+images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={closeLightbox}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          />
        )}
      </div>
    );
  }

  const RequestForm = ({id}) => {
    const [fullname,setFullName] = useState("")
    const [email,setEmail] = useState('')
    const [telephone,setTelephone] = useState('')
    const [from,setFrom] = useState('')
    const [to,setTo] = useState('')
    const [insuranceFile,setInsuranceFile] = useState(null)
    const [licence,setLicence] = useState(null)
    const [isRequestRun,setIsRequestRun] = useState(false)

    const [msg,setMsg] = useState('')
    const [success,setSuccess] = useState('')

    const sendRequest = async() =>{
        setIsRequestRun(true)
        if(fullname !== '' && email !== '' &&telephone !== '' && from !== '' && to !== ''){
            let licence_file = ''
            let insurance_file = ''
            if(insuranceFile !== null){
                const FirstformData = new FormData();
                FirstformData.append('image', insuranceFile)
                const res = await axios.post(`https://upload.mivanrental.com`,FirstformData)
                insurance_file = res.data.name
            }
            

            setTimeout(async()=>{
                if(licence !== null){
                    const SecondformData = new FormData();
                    SecondformData.append('image', licence)
                    const Secondres = await axios.post(`https://upload.mivanrental.com`,SecondformData)
                    licence_file = Secondres.data.name
    
                }
                setTimeout(async()=>{
                    const RequestRes = await axios.post(`https://api.mivanrental.com`,{
                        query:`mutation{
                            AddNewRequest(vehicle:"${id}",from:"${from}",to:"${to}",fullname:"${fullname}",email:"${email}",telephone:"${telephone}",insurance:"${insurance_file}",licence:"${licence_file}"){
                              id
                              Duration{
                                  From
                                  To
                              }
                              MainInfo{
                                  FullName
                                  Email
                                  Telephone
                              }
                              Documents{ 
                                  Insurance
                                  DrivingLiscence
                              }
                            }
                        }`
                    })
                    if(RequestRes.data.data.AddNewRequest.id){
                        setMsg('')
                        setSuccess('Request Has Been Sent Successfully')
                        setFullName('')
                        setEmail('')
                        setTelephone('')
                        setTo('')
                        setFrom('')
                        setInsuranceFile(null)
                        setLicence(null)
                        setIsRequestRun(false)

                    }
                },1000)


            },1000)
            
        }
        else{
            setMsg('All fields required')
            setIsRequestRun(false)

        }

    }
  
    return (
      <div className=" bg-gray-900 w-full h-auto p-4 rounded-lg  shadow-lg sticky top-10">
        <h2 className="text-2xl font-bold mb-4 text-white">Submit a request for the vehicle</h2>
        
          <div className="mb-4">
            <label htmlFor="fullname" className="block text-gray-300 text-sm font-semibold mb-2">
              Full Name
            </label>
            <input
              type="text"
              id="fullname"
              value={fullname}
              onChange={(e)=>setFullName(e.target.value)}
              name="fullname"
              className="w-full p-2 border outline-none rounded-md"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="fullname" className="block text-gray-300 text-sm font-semibold mb-2">
              Email
            </label>
            <input
              type="email"
              id="fullname"
              value={email}
              onChange={(e)=>setEmail(e.target.value)}
              name="fullname"
              className="w-full p-2 border outline-none rounded-md"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="fullname" className="block text-gray-300 text-sm font-semibold mb-2">
              Telephone
            </label>
            <input
              type="telephone"
              id="fullname"
              value={telephone}
              onChange={(e)=>setTelephone(e.target.value)}
              name="fullname"
              className="w-full p-2 border outline-none rounded-md"
              required
            />
          </div>
          <div className="mb-4 grid grid-cols-2 gap-4">
            <div>
            <label htmlFor="fullname" className="block text-gray-300 text-sm font-semibold mb-2">
              From
            </label>
            <input
              type="date"
              id="fullname"
              name="fullname"
              value={from}
              onChange={(e)=>setFrom(e.target.value)}
              className="w-full p-2 border outline-none rounded-md"
              required
            />
            </div>
            <div>
            <label htmlFor="fullname" className="block text-gray-300 text-sm font-semibold mb-2">
              To
            </label>
            <input
              type="date"
              id="fullname"
              name="fullname"
              value={to}
              onChange={(e)=>setTo(e.target.value)}
              className="w-full p-2 border outline-none rounded-md"
              required
            />
            </div>
          </div>

          <div className="mb-4 grid grid-cols-2 gap-4">
            <div class="flex items-center justify-center">
                <label title="inorder to rent a van you should have full cover insurance, please upload a copy of your insurance policy." for="file-upload" class="cursor-pointer bg-[#004e7e] text-white w-full font-bold py-2 px-4 rounded-md">
                    Insurance {insuranceFile !== null && (<span className="text-xs text-green-500 inherit">Selected</span>)}
                </label>
                <input id="file-upload" type="file"  onChange={(e)=>setInsuranceFile(e.target.files[0])} class="hidden" />
            </div>
            <div class="flex items-center justify-center">
                <label title=" your driving licence should be valid, please upload a copy of your driving licence." for="file-upload_licence" class="cursor-pointer bg-[#004e7e] text-white w-full font-bold py-2 px-4 rounded-md">
                Driving Licence {licence !== null && <span className="text-xs text-green-500 ml-2">Selected</span>}
                </label>
                <input id="file-upload_licence"  onChange={(e)=>setLicence(e.target.files[0])} type="file" class="hidden" />
            </div>
          </div>
  
        <div className="flex justify-end">
        
        <button disabled={isRequestRun} onClick={sendRequest} type="submit" className="bg-green-900 mt-4 text-white p-2 rounded-md">
          {
            isRequestRun ? <div class="w-6 h-6 rounded-full animate-spin border-4 border-solid border-white border-t-transparent"></div> : <>Submit Request</> 
          }
            
          </button>
        </div>
        <p className="mt-2 text-red-500 text-center w-full ">{msg&& msg}</p>
        <p className="mt-2 text-green-500 text-center w-full ">{success&& success}</p>
         
      </div>
    );
  }


const Vehicle = ({ vehicles }) => {
  const [vehicle, setVehicle] = useState(null);
  const { vehicleSlug } = useParams();

  const renderFeatureRow = (label1, value1, label2, value2) => (
    <tr key={label1} className="border-b">
      <td className="py-2 text-center px-4 font-bold text-gray-800  p-2 text-white bg-[#004e7e]  border-r">{label1}</td>
      <td className="text-lg text-center p-2 border-r">{typeof value1 === "boolean" ? renderBooleanIcon(value1) : value1}</td>
      <td className="py-2 text-center px-4 font-bold text-gray-800  p-2 text-white bg-[#004e7e]  border-r">{label2}</td>
      <td className="text-lg text-center p-2">{typeof value2 === "boolean" ? renderBooleanIcon(value2) : value2}</td>
    </tr>
  )

  const renderFeaturesRowMobile = (label,value) => (
    <tr key={label} className="border-b">
      <td className="py-2 text-center px-4 font-bold text-gray-800  p-2 text-white bg-[#004e7e]  border-r">{label}</td>
      <td className="text-lg text-center p-2 border-r">{typeof value === "boolean" ? renderBooleanIcon(value) : value}</td>
    </tr>
  )

  const renderBooleanIcon = (value) => (
    <i
    
      className={value ? " text-green-500 fa-solid fa-check" : " text-red-500 fa-solid fa-xmark" }
    ></i>
  );

  const getEmbedLink = (youtubeUrl) =>{
    let videoId = extractVideoId(youtubeUrl);
    if (videoId) {
      return `https://www.youtube.com/embed/${videoId}`;
    } else {
      return 'Invalid YouTube URL';
    }
  }
  
  const extractVideoId = (url) => {
    let regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    let match = url.match(regExp);
    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return null;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = 'Michigan Car & Van Rental | '+ vehicles.find((car) => car.MainInfo.Slug === vehicleSlug).MainInfo.Brand.Name + ' ' + vehicles.find((car) => car.MainInfo.Slug === vehicleSlug).MainInfo.Model

    setVehicle(vehicles.find((car) => car.MainInfo.Slug === vehicleSlug));
  }, [vehicles]);

  return (
    <>
      <div className="container mx-auto px-5 lg:px-0 my-8">
      <div className="brand mb-4 border-b pb-4 flex justify-center flex-col align-center items-center">
                <img className="w-[110px]" src={"https://cloud.mivanrental.com/"+vehicle?.MainInfo?.Brand?.Logo} alt="" />
                <h1 className="text-2xl mx-1 font-bold">{vehicle?.MainInfo?.Brand?.Name } {vehicle?.MainInfo?.Model}</h1>
            </div>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:gap-8">
          <div className="col-span-2">
            <img
              src={`https://cloud.mivanrental.com/${vehicle?.MainImage?.FilePath}`}
              alt={vehicle?.MainInfo?.Title}
              className="w-full h-auto rounded-lg shadow-lg mb-4"
            />


<div className="p-2">
        <h2 className="text-xl lg:text-4xl font-bold mb-4 text-gray-800">
              {vehicle?.MainInfo?.Title}
            </h2>
            <Gallery images={vehicle?.Galleries}/>

            <p className="text-gray-600 mb-6 mt-2 text-lg">
              {vehicle?.MainInfo?.Description}
            </p>
         
         
           
        </div>
<div className="flex flex-col justify-center">
         

            <div class="relative hidden lg:block overflow-x-auto shadow-lg sm:rounded-lg">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <tbody  className=" rounded-xl">
              {Object.keys(vehicle?.Features  || {}).map((key, index) => {
              const isEvenIndex = index % 2 === 0;

              if (isEvenIndex) {
                const nextKey = Object.keys(vehicle?.Features)[index + 1];
                const value1 = vehicle?.Features[key];
                const value2 = vehicle?.Features[nextKey];
                return renderFeatureRow(key, value1, nextKey, value2);
              }
              return null;
            })}
              </tbody>
            </table>
            </div>
            <div class="relative lg:hidden overflow-x-auto shadow-lg sm:rounded-lg">

            <table class="w-full  text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <tbody  className=" rounded-xl">
              {Object.keys(vehicle?.Features  || {}).map((key, index) => {
                  const value = vehicle?.Features[key];
                  return renderFeaturesRowMobile(key, value)
            })}
              </tbody>
            </table>
            </div>
           
           
          </div>
            
          <div className="w-full mt-6 rounded-lg">
            {
              (vehicle?.YoutubeLink && vehicle?.YoutubeLink !== '') && <iframe className="w-full aspect-video" src={getEmbedLink(vehicle?.YoutubeLink)} title="Arch Enemy - Live at Resurrection Fest EG 2019 (Viveiro, Spain) [Pro-shot, Full Show]" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

            }
          </div>
          </div>
          <div className="mt-10 lg:mt-0">
          <RequestForm id={vehicle?.id} /> 

          </div>

          
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4">

       
        <div className="w-full flex justify-end ">

        </div>
        </div>
      
      </div>
    </>
  );
};

export default Vehicle;
