import React, { useEffect } from 'react';
import car from './../Images/CarFacts.png'

const AboutUs = () => {

  useEffect(()=>{
    window.scrollTo(0, 0)
    document.title = 'Michigan Car & Van Rental | About Us';


  },[])
  return (
    <div className="bg-gray-100 py-16">
      <div className="container px-5 lg:px-0 mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="text-center md:text-left">
            <h1 className="text-4xl lg:text-5xl text-left font-extrabold text-gray-800 mb-4">
              About Us
            </h1>
            <p className="text-md text-left lg:text-xl text-gray-700">
            MICHIGAN CAR AND VAN RENTAL Inc. has been family owned and operated since 1992. What sets us apart from all the other van rental companies is our personalized customer service. We go out of our way for our customers in many ways. We provide you with the specific service that you need. for van rental (586)-755-9290

            </p>
          </div>
          <div className="text-center">
            <img
              src="https://static.tcimg.net/vehicles/primary/36a2ff4c043c7527/2023-Mercedes-Benz-Metris_Passenger_Van-silver-full_color-driver_side_front_quarter.png"
              alt="About Us"
              className="mx-auto w-96"
            />
          </div>
        </div>
        <div className="mt-12 text-center">
          <h2 className="text-2xl lg:text-3xl text-left font-extrabold text-gray-800 mb-4">Our Mission</h2>
          <p className="text-md lg:text-lg text-left text-gray-700">
            Michigan Van Rental offers some of the best luxury car rental in Detroit Michigan.
            We guarantee your rental on Pickup. Most rental companies in Detroit do not guarantee that your rental will not be given to someone else.
          </p>
        </div>
      </div>
      <div className="bg-gray-200 mt-10 py-16">
        <div className="container px-5 lg:px-0 mx-auto">
          <h2 className="text-3xl font-extrabold text-gray-800 text-left lg:text-center mb-8">Our Values</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="text-center p-6 rounded-lg bg-white shadow-lg">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">Customer-Centric</h3>
              <p className="text-gray-700">
                Our customers' satisfaction and needs are at the heart of everything we do.
              </p>
            </div>
            <div className="text-center p-6 rounded-lg bg-white shadow-lg">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">Quality Vehicles</h3>
              <p className="text-gray-700">
                We provide high-quality and well-maintained vehicles for your safety and comfort.
              </p>
            </div>
            <div className="text-center p-6 rounded-lg bg-white shadow-lg">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">Innovation</h3>
              <p className="text-gray-700">
                We embrace technology to offer convenience and improve your travel experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
