import axios from 'axios';
import React, { useEffect, useState } from 'react';

const ContactForm = () => {

    const [fullname,setFullName] = useState('')
    const [email,setEmail] = useState('')
    const [phone,setPhone] = useState('')
    const [subject,setSubject] = useState('')
    const [msg,setMessage] = useState('')

    const [res,setRes] = useState('')
    

    const handleClick = async() =>{
        if(fullname !== '' && email !== '' && subject !== '' && msg !== ''){
            const res = await axios.post(`https://api.mivanrental.com`,{
            query:`mutation{
                AddNewInbox(fullname:"${fullname}",email:"${email}",phonenumber:"${phone}",subject:"${subject}",message:"${msg}"){
                    id
                }
            }`
        })
        if(res.data.data.AddNewInbox.id){
            setRes('SENT')
        }
        else{
            setRes('ERR')
        }
        }
        else{
            setRes('MISS')
        }
        
    }
  useEffect(()=>{
    document.title = 'Michigan Car & Van Rental | Contact Us';
  },[])
  return (
    
    <>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d618.7734326761462!2d-83.00589546595408!3d42.46180036802178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8824d0da68c5fcab%3A0x2013462fd0cad2be!2s22768%20Hoover%20Rd%2C%20Warren%2C%20MI%2048089%2C%20USA!5e0!3m2!1sen!2sde!4v1701081032585!5m2!1sen!2sde"  style={{border:0,width:"100%",height:"50vh"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

    <div className="max-w-screen-lg mx-auto p-5">
      <div className="grid grid-cols-1 md:grid-cols-12 border rounded-lg">
        <div className="bg-gray-900 md:col-span-4 p-10 text-white rounded-t-lg  md:rounded-l-lg">
          <p className="mt-4 text-sm leading-7 font-regular uppercase">Contact</p>
          <h3 className="text-3xl sm:text-4xl leading-normal font-extrabold tracking-tight">
            Get In <span className="text-[#004e7e]">Touch</span>
          </h3>
          <p className="mt-4 leading-7 text-gray-200">
          Michigan Car and Van Rental is proud to provide locals and tourists in Warren with the best in car, van, and truck rentals. Our convenient locations make it easy to attend sporting events in the greater Detroit area and visit any of the theater venues the city has to offer. If you live in the area or you plan on visiting us soon, rely on our services to get you where you need to be.

          </p>

          <div className="flex items-center mt-5">
          <i class="fa-solid fa-location-dot mx-2 text-[#004e7e] text-xl"></i>
            <span className="text-sm">22768 Hoover Rd, Warren MI 48089</span>
          </div>
          <div className="flex items-center mt-5">
          <i class="fa-solid fa-phone mx-2 text-[#004e7e] text-xl"></i>
            <span className="text-sm">(586) 755-9290</span>
          </div>
          <div className="flex items-center mt-5">
            <i class="fa-solid fa-envelope  mx-2 text-[#004e7e] text-xl"></i>
            <span className="text-sm">support@mivanrenta.com</span>
          </div>
        </div>
        <div className="md:col-span-8 p-10">
          <p className='mb-5 text-sm text-gray-400'>
          If you have questions about our rental services, please fill out this form and we will contact you promptly.
          </p>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">Full name</label>
              <input value={fullname} onChange={(e)=>setFullName(e.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-email" type="email" placeholder="John Doe ..." />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">Email</label>
              <input value={email} onChange={(e)=>setEmail(e.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="email" placeholder="********@*****.**" />
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">Phone Number</label>
              <input value={phone} onChange={(e)=>setPhone(e.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="tel" placeholder="00 123 456 789" />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">Subject</label>
              <input value={subject} onChange={(e)=>setSubject(e.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-email" type="email" placeholder="Message Subject ..." />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">Your Message</label>
              <textarea value={msg} onChange={(e)=>setMessage(e.target.value)} rows="10" className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"></textarea>
            </div>
            <div className="flex justify-end w-full px-3">
             
              <button onClick={handleClick} className="shadow bg-[#004e7e] hover:bg-[#004e7edd] focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded" type="submit">Send Message</button>
            </div>
            {
               res !== '' && (res === 'SENT' ? <p className='text-green-700 w-full mt-3 block text-center'>Message Has Sent Successfully</p> : res === "ERR" ? <p className='text-red-700 w-full mt-3 block text-center'>Try Again Later </p>: <p className='text-red-700 w-full mt-3 block text-center'>Make sure all required fields filled</p> )
            }
          </div>
        </div>
      </div>
    </div>
    </>
   
  );
};

export default ContactForm;
